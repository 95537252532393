import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { ModalProgressBar } from "../controls";
import { createProfile } from "./_redux/ProfileActions";
import { toast } from "react-toastify";
import { UploadCsvDialog } from "./lead-csv-dialog/UploadCsvDialog";

function ReviewForm({ profile, user }) {
  // Fields
  const [loading, setloading] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [displayFile, setDisplayFile] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  // const user = useSelector(state => state.auth.user, shallowEqual);

  const showUploadResumeModal = () => {
    setShowModal(true);
  };

  const handleCloseResumeModal = clearFile => {
    setShowModal(false);
    clearFile();
  };
  // Methods
  const saveUser = (values, setStatus, setSubmitting) => {
    setloading(true);
    dispatch(
      createProfile({
        profile: {
          ...values,
          firstName: user?.user_detail.name,
          email: user?.user_detail.email
        },
        account_id: user?.user_detail.account_id
      })
    ).then(res => {
      if (res.data.status) {
        setloading(false);
        setSubmitting(false);
        toast.success("Profile uploaded successfully!");
        // toggleFormElements(false);
      } else {
        setloading(false);
        setSubmitting(false);
        toast.error(
          "Something went wrong! Please try again or contact an Admin"
        );
      }
    });

    setloading(false);
    setSubmitting(false);
  };
  function toggleFormElements(bDisabled) {
    setToggleEdit(bDisabled);
    // var inputs = document.getElementsByTagName("input");
    // for (var i = 0; i < inputs.length; i++) {
    //   if (inputs[i].disabled) inputs[i].disabled = bDisabled;
    // }
    // var radios = document.getElementsByTagName("radio");
    // for (var i = 0; i < radios.length; i++) {
    //   if (radios[i].disabled) radios[i].disabled = bDisabled;
    // }
  }
  // UI Helpers
  const initialValues = {
    linkedInUrl: "",
    phone: "",
    jobRole: "",
    isCareerJobRole: "",
    yearsOfExperience: "",
    activelyLookingForJob: "",
    workedInCanadaOrUK: "",
    confidentWithSalesforce: "",
    groupMeetingAvailability: "",
    interestedInGroupLeader: "",
    // programRegistered: "",
    currentLocation: "",
    hoursPerWeek: "",
    resumeUrl: "",
    workAuthorization: "",
    trailheadProfile: "",
    firstSubscription: "",
    firstCohort: "",
    paypalEmail: ""
  };
  const Schema = Yup.object().shape({
    phone: Yup.string().required("This field is required"),
    linkedInUrl: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter a valid url!"
      )
      .required("This field is required"),
    trailheadProfile: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter a valid url!"
      )
      .required("This field is required"),
    jobRole: Yup.string().required("This field is required"),
    paypalEmail: Yup.string().required("This field is required"),
    isCareerJobRole: Yup.string().required("This field is required"),
    yearsOfExperience: Yup.string().required("This field is required"),
    activelyLookingForJob: Yup.string().required("This field is required"),
    workedInCanadaOrUK: Yup.string().required("This field is required"),
    hoursPerWeek: Yup.string().required("This field is required"),
    confidentWithSalesforce: Yup.string().required("This field is required"),
    groupMeetingAvailability: Yup.string().required("This field is required"),
    // programRegistered: Yup.string().required("This field is required"),
    currentLocation: Yup.string().required("This field is required"),
    interestedInGroupLeader: Yup.string().required("This field is required"),
    resumeUrl: Yup.string().required("This field is required"),
    firstSubscription: Yup.string().required("This field is required"),
    firstCohort: Yup.string().required("This field is required"),
    workAuthorization: Yup.string().required("This field is required")
  });
  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues: profile != undefined ? profile : initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting, initialValues }) => {
      Swal.fire({
        title: "Are you sure?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit"
      }).then(result => {
        if (result.isConfirmed) {
          saveUser(values, setStatus, setSubmitting);
          // Swal.fire("Deleted!", "Your file has been deleted.", "success");
        } else {
          setSubmitting(false);
        }
      });
      // saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {}
  });

  useEffect(() => {
    if (profile) {
      setToggleEdit(true);
    }
  }, [profile]);

  return (
    <>
      <form className="card card-custom" onSubmit={formik.handleSubmit}>
        {loading && <ModalProgressBar />}

        {/* begin::Header */}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              Blackforce Paid Members Profile Review
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              {/* Please, fill out this form as accurately as possible.{" "} */}
              {/* <span className="text-danger">
                {" "}
                Please, fill out this form as accurately as possible. Form
                closes on 25th January 2023!
              </span> */}
            </span>
          </div>
          <div className="card-toolbar">
            {profile && !toggleEdit && (
              <>
                <button
                  type="submit"
                  className="btn btn-success mr-2"
                  disabled={formik.isSubmitting}
                >
                  Save Changes
                  {formik.isSubmitting}
                </button>
              </>
            )}
            {!profile ? (
              <>
                <button
                  type="submit"
                  className="btn btn-success mr-2"
                  disabled={formik.isSubmitting}
                >
                  Save Changes
                  {formik.isSubmitting}
                </button>
              </>
            ) : (
              <>
                {/* <span
              disabled={true}
              className="btn btn-success mr-2"
              onClick={() => toggleFormElements(false)}
               >
                Submitted
               </span> */}
                <span
                  className="btn btn-primary mr-2"
                  onClick={() => toggleFormElements(false)}
                >
                  Update
                </span>
              </>
            )}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body">
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Contact Phone
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  type="number"
                  disabled={toggleEdit}
                  placeholder="Phone Number"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "phone"
                  )}`}
                  name="phone"
                  {...formik.getFieldProps("phone")}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="invalid-feedback">{formik.errors.phone}</div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                What is your LinkedIn Profile URL
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  disabled={toggleEdit}
                  type="text"
                  placeholder="LinkedIn URL"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "linkedInUrl"
                  )}`}
                  name="linkedInUrl"
                  {...formik.getFieldProps("linkedInUrl")}
                />
                {formik.touched.linkedInUrl && formik.errors.linkedInUrl ? (
                  <div className="invalid-feedback">
                    {formik.errors.linkedInUrl}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Provide your PAYPAL email (Must be correct)
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  disabled={toggleEdit}
                  type="text"
                  placeholder="Paypal Email"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "paypalEmail"
                  )}`}
                  name="paypalEmail"
                  {...formik.getFieldProps("paypalEmail")}
                />
                {formik.touched.paypalEmail && formik.errors.paypalEmail ? (
                  <div className="invalid-feedback">
                    {formik.errors.paypalEmail}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Your Trailhead Profile URL (Must be correct)
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  disabled={toggleEdit}
                  type="text"
                  placeholder="Trailhead url"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "trailheadProfile"
                  )}`}
                  name="trailheadProfile"
                  {...formik.getFieldProps("trailheadProfile")}
                />
                {formik.touched.trailheadProfile &&
                formik.errors.trailheadProfile ? (
                  <div className="invalid-feedback">
                    {formik.errors.trailheadProfile}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                What is your current Job Role?
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  disabled={toggleEdit}
                  type="text"
                  placeholder="Current Job Role?"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "jobRole"
                  )}`}
                  name="jobRole"
                  {...formik.getFieldProps("jobRole")}
                />
                {formik.touched.jobRole && formik.errors.jobRole ? (
                  <div className="invalid-feedback">
                    {formik.errors.jobRole}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Is this a Career Job Role?
              </label>
              <div className="col-lg-9 col-xl-6">
                <div className="col-form-label">
                  <div className="radio-inline">
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                          "isCareerJobRole"
                        )}`}
                        name="isCareerJobRole"
                        {...formik.getFieldProps("isCareerJobRole")}
                        value="yes"
                        checked={formik.values.isCareerJobRole === "yes"}
                        onChange={() =>
                          formik.setFieldValue("isCareerJobRole", "yes")
                        }
                      />
                      <span></span>
                      Yes
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                          "isCareerJobRole"
                        )}`}
                        name="isCareerJobRole"
                        {...formik.getFieldProps("isCareerJobRole")}
                        value="no"
                        checked={formik.values.isCareerJobRole === "no"}
                        onChange={() =>
                          formik.setFieldValue("isCareerJobRole", "no")
                        }
                      />
                      <span></span>
                      No
                    </label>
                    <input
                      hidden
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "isCareerJobRole"
                      )}`}
                      {...formik.getFieldProps("isCareerJobRole")}
                    />
                    {formik.touched.isCareerJobRole &&
                    formik.errors.isCareerJobRole ? (
                      <div className="invalid-feedback">
                        {formik.errors.isCareerJobRole}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                How many years of work experience do you have in a Professional
                Job?
              </label>
              <div className="col-lg-9 col-xl-6">
                <div className=" col-form-label">
                  <div className="radio-inline">
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="yearsOfExperience"
                        value="0 - 1 year"
                        checked={
                          formik.values.yearsOfExperience === "0 - 1 year"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "yearsOfExperience",
                            "0 - 1 year"
                          )
                        }
                      />
                      <span></span>0 - 1 year
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="yearsOfExperience"
                        value="2 - 5 years"
                        checked={
                          formik.values.yearsOfExperience === "2 - 5 years"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "yearsOfExperience",
                            "2 - 5 years"
                          )
                        }
                      />
                      <span></span>2 - 5 years
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="yearsOfExperience"
                        value="6 - 10 years"
                        checked={
                          formik.values.yearsOfExperience === "6 - 10 years"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "yearsOfExperience",
                            "6 - 10 years"
                          )
                        }
                      />
                      <span></span>6 - 10 years
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="yearsOfExperience"
                        value="Over 10 years"
                        checked={
                          formik.values.yearsOfExperience === "Over 10 years"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "yearsOfExperience",
                            "Over 10 years"
                          )
                        }
                      />
                      <span></span>Over 10 years
                    </label>
                  </div>
                </div>
                <input
                  hidden
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "yearsOfExperience"
                  )}`}
                  {...formik.getFieldProps("yearsOfExperience")}
                />

                {formik.touched.yearsOfExperience &&
                formik.errors.yearsOfExperience ? (
                  <div className="invalid-feedback">
                    {formik.errors.yearsOfExperience}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Are you actively looking for a job?
              </label>
              <div className="col">
                <div className="col-form-label">
                  <div className="radio-inline">
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="activelyLookingForJob"
                        value="yes"
                        checked={formik.values.activelyLookingForJob === "yes"}
                        onChange={() =>
                          formik.setFieldValue("activelyLookingForJob", "yes")
                        }
                      />
                      <span></span>Yes (Need a new job right now)
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="activelyLookingForJob"
                        value="maybe"
                        checked={
                          formik.values.activelyLookingForJob === "maybe"
                        }
                        onChange={() =>
                          formik.setFieldValue("activelyLookingForJob", "maybe")
                        }
                      />
                      <span></span>Maybe (I'll change if offer is better than
                      present job)
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="activelyLookingForJob"
                        value="no"
                        checked={formik.values.activelyLookingForJob === "no"}
                        onChange={() =>
                          formik.setFieldValue("activelyLookingForJob", "no")
                        }
                      />
                      <span></span>No
                    </label>
                  </div>
                </div>
                <input
                  hidden
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "activelyLookingForJob"
                  )}`}
                  {...formik.getFieldProps("activelyLookingForJob")}
                />
                {formik.touched.activelyLookingForJob &&
                formik.errors.activelyLookingForJob ? (
                  <div className="invalid-feedback">
                    {formik.errors.activelyLookingForJob}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Have you ever worked in Canada or USA (Professionally) for more
                than a year?
              </label>
              <div className="col">
                <div className="col-form-label">
                  <div className="radio-inline">
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="workedInCanadaOrUK"
                        value="yes"
                        checked={formik.values.workedInCanadaOrUK === "yes"}
                        onChange={() =>
                          formik.setFieldValue("workedInCanadaOrUK", "yes")
                        }
                      />
                      <span></span>Yes
                    </label>

                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="workedInCanadaOrUK"
                        value="no"
                        checked={formik.values.workedInCanadaOrUK === "no"}
                        onChange={() =>
                          formik.setFieldValue("workedInCanadaOrUK", "no")
                        }
                      />
                      <span></span>No
                    </label>
                  </div>
                </div>
                <input
                  hidden
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "workedInCanadaOrUK"
                  )}`}
                  {...formik.getFieldProps("workedInCanadaOrUK")}
                />
                {formik.touched.workedInCanadaOrUK &&
                formik.errors.workedInCanadaOrUK ? (
                  <div className="invalid-feedback">
                    {formik.errors.workedInCanadaOrUK}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                How many hours are you currently putting into this training per
                week?
              </label>
              <div className="col-lg-9 col-xl-6">
                <div className="col-form-label">
                  <div className="radio-inline">
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="hoursPerWeek"
                        value="10 hours"
                        checked={formik.values.hoursPerWeek === "10 hours"}
                        onChange={() =>
                          formik.setFieldValue("hoursPerWeek", "10 hours")
                        }
                      />
                      <span></span>10 hours - I only attend classes
                    </label>

                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="hoursPerWeek"
                        value="10 - 15 hours"
                        checked={formik.values.hoursPerWeek === "10 - 15 hours"}
                        onChange={() =>
                          formik.setFieldValue("hoursPerWeek", "10 - 15 hours")
                        }
                      />
                      <span></span>10 - 15 hours - I attend classes and
                      occasionally clinic & tutorials
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="hoursPerWeek"
                        value="15 - 20 hours"
                        checked={formik.values.hoursPerWeek === "15 - 20 hours"}
                        onChange={() =>
                          formik.setFieldValue("hoursPerWeek", "15 - 20 hours")
                        }
                      />
                      <span></span>15 - 20 hours - I attend classes and clinic,
                      tutorials, & study Independently
                    </label>
                  </div>
                </div>
                <input
                  hidden
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "hoursPerWeek"
                  )}`}
                  {...formik.getFieldProps("hoursPerWeek")}
                />
                {formik.touched.hoursPerWeek && formik.errors.hoursPerWeek ? (
                  <div className="invalid-feedback">
                    {formik.errors.hoursPerWeek}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                How confident are you with the Salesforce Platform?
              </label>
              <div className="col-lg-9 col-xl-6">
                <div className="col-form-label">
                  <div className="radio-inline">
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="confidentWithSalesforce"
                        value="Poor"
                        checked={
                          formik.values.confidentWithSalesforce === "Poor"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "confidentWithSalesforce",
                            "Poor"
                          )
                        }
                      />
                      <span></span>Poor - I'm lost. I honestly don't know what
                      is going on. I need help.
                    </label>

                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="confidentWithSalesforce"
                        value="Average"
                        checked={
                          formik.values.confidentWithSalesforce === "Average"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "confidentWithSalesforce",
                            "Average"
                          )
                        }
                      />
                      <span></span>Average - I get it. I just need more time to
                      practise. I can figure it out.
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="confidentWithSalesforce"
                        value="Good"
                        checked={
                          formik.values.confidentWithSalesforce === "Good"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "confidentWithSalesforce",
                            "Good"
                          )
                        }
                      />
                      <span></span>Good - I know what's going on. I barely need
                      help during my trailhead.
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="confidentWithSalesforce"
                        value="Very Good"
                        checked={
                          formik.values.confidentWithSalesforce === "Very Good"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "confidentWithSalesforce",
                            "Very Good"
                          )
                        }
                      />
                      <span></span>Very Good - I'm quite confident. I can find
                      solutions to answers. I can lead the group.
                    </label>
                  </div>
                </div>
                <input
                  hidden
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "confidentWithSalesforce"
                  )}`}
                  {...formik.getFieldProps("confidentWithSalesforce")}
                />
                {formik.touched.confidentWithSalesforce &&
                formik.errors.confidentWithSalesforce ? (
                  <div className="invalid-feedback">
                    {formik.errors.confidentWithSalesforce}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                What's your availability like for (Smaller Sized) Group
                Mentoring / Project Discussion
              </label>
              <div className="col-lg-9 col-xl-6">
                <div className="col-form-label">
                  <div className="radio-inline">
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="groupMeetingAvailability"
                        value="Weekdays"
                        checked={
                          formik.values.groupMeetingAvailability === "Weekdays"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "groupMeetingAvailability",
                            "Weekdays"
                          )
                        }
                      />
                      <span></span>Weekdays
                    </label>

                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="groupMeetingAvailability"
                        value="Weekends"
                        checked={
                          formik.values.groupMeetingAvailability === "Weekends"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "groupMeetingAvailability",
                            "Weekends"
                          )
                        }
                      />
                      <span></span>Weekends
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="groupMeetingAvailability"
                        value="I'm flexible"
                        checked={
                          formik.values.groupMeetingAvailability ===
                          "I'm flexible"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "groupMeetingAvailability",
                            "I'm flexible"
                          )
                        }
                      />
                      <span></span>I'm flexible
                    </label>
                  </div>
                </div>
                <input
                  hidden
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "groupMeetingAvailability"
                  )}`}
                  {...formik.getFieldProps("groupMeetingAvailability")}
                />
                {formik.touched.groupMeetingAvailability &&
                formik.errors.groupMeetingAvailability ? (
                  <div className="invalid-feedback">
                    {formik.errors.groupMeetingAvailability}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Where are you based?
              </label>
              <div className="col-lg-9 col-xl-6">
                <div className="col-form-label">
                  <div className="radio-inline">
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="currentLocation"
                        value="Canada / USA"
                        checked={
                          formik.values.currentLocation === "Canada / USA"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "currentLocation",
                            "Canada / USA"
                          )
                        }
                      />
                      <span></span>Canada / USA
                    </label>

                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="currentLocation"
                        value="Europe (Including England)"
                        checked={
                          formik.values.currentLocation ===
                          "Europe (Including England)"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "currentLocation",
                            "Europe (Including England)"
                          )
                        }
                      />
                      <span></span>Europe (Including England)
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="currentLocation"
                        value="Africa (Including Nigeria)"
                        checked={
                          formik.values.currentLocation ===
                          "Africa (Including Nigeria)"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "currentLocation",
                            "Africa (Including Nigeria)"
                          )
                        }
                      />
                      <span></span>Africa (Including Nigeria)
                    </label>
                  </div>
                </div>
                <input
                  hidden
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "currentLocation"
                  )}`}
                  {...formik.getFieldProps("currentLocation")}
                />
                {formik.touched.currentLocation &&
                formik.errors.currentLocation ? (
                  <div className="invalid-feedback">
                    {formik.errors.currentLocation}
                  </div>
                ) : null}
              </div>
            </div>
            {/* <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                What did you register for on Blackforce?
              </label>
              <div className="col-lg-9 col-xl-9">
                <div className="col-form-label">
                  <div className="radio-inline">
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="programRegistered"
                        value="Salesforce Career Development"
                        checked={
                          formik.values.programRegistered ===
                          "Salesforce Career Development"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "programRegistered",
                            "Salesforce Career Development"
                          )
                        }
                      />
                      <span></span>Salesforce Career Development
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="programRegistered"
                        value="Business Analysis Career Development"
                        checked={
                          formik.values.programRegistered ===
                          "Business Analysis Career Development"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "programRegistered",
                            "Business Analysis Career Development"
                          )
                        }
                      />
                      <span></span>Business Analysis Career Development
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="programRegistered"
                        value="Technical Career Development"
                        checked={
                          formik.values.programRegistered ===
                          "Technical Career Development"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "programRegistered",
                            "Technical Career Development"
                          )
                        }
                      />
                      <span></span>Technical Career Development
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="programRegistered"
                        value="Data Analytics Career Development"
                        checked={
                          formik.values.programRegistered ===
                          "Data Analytics Career Development"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "programRegistered",
                            "Data Analytics Career Development"
                          )
                        }
                      />
                      <span></span>Data Analytics Career Development
                    </label>

                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="programRegistered"
                        value="DevOps Career Development"
                        checked={
                          formik.values.programRegistered ===
                          "DevOps Career Development"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "programRegistered",
                            "DevOps Career Development"
                          )
                        }
                      />
                      <span></span>DevOps Career Development
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="programRegistered"
                        value="Cybersecurity Career Development"
                        checked={
                          formik.values.programRegistered ===
                          "Cybersecurity Career Development"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "programRegistered",
                            "Cybersecurity Career Development"
                          )
                        }
                      />
                      <span></span>Cybersecurity Career Development
                    </label>
                  </div>
                </div>
                <input
                  hidden
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "programRegistered"
                  )}`}
                  {...formik.getFieldProps("programRegistered")}
                />
                {formik.touched.programRegistered &&
                formik.errors.programRegistered ? (
                  <div className="invalid-feedback">
                    {formik.errors.programRegistered}
                  </div>
                ) : null}
              </div>
            </div> */}

            <div className="form-group row">
              <label className="col-xl-3 col-lg-13 col-form-label">
                Indicate your FIRST BlackForce Membership
              </label>

              <div className="col-lg-9 col-xl-6">
                <div className="col-form-label">
                  <div className="radio-inline">
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="firstSubscription"
                        value="Basic"
                        checked={formik.values.firstSubscription === "Basic"}
                        onChange={() =>
                          formik.setFieldValue("firstSubscription", "Basic")
                        }
                      />
                      <span></span>Basic
                    </label>

                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="firstSubscription"
                        value="Standard"
                        checked={formik.values.firstSubscription === "Standard"}
                        onChange={() =>
                          formik.setFieldValue("firstSubscription", "Standard")
                        }
                      />
                      <span></span>Standard
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="firstSubscription"
                        value="Professional"
                        checked={
                          formik.values.firstSubscription === "Professional"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "firstSubscription",
                            "Professional"
                          )
                        }
                      />
                      <span></span>Professional
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="firstSubscription"
                        value="Special Program"
                        checked={
                          formik.values.firstSubscription === "Special Program"
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "firstSubscription",
                            "Special Program"
                          )
                        }
                      />
                      <span></span>Special Program
                    </label>
                  </div>
                </div>
                <input
                  hidden
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "firstSubscription"
                  )}`}
                  {...formik.getFieldProps("firstSubscription")}
                />
                {formik.touched.firstSubscription &&
                formik.errors.firstSubscription ? (
                  <div className="invalid-feedback">
                    {formik.errors.firstSubscription}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Indicate your FIRST BlackForce Cohort
              </label>

              <div className="col-lg-9 col-xl-6">
                <div className="col-form-label">
                  <div className="radio-inline">
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="firstCohort"
                        value="Cohort 0"
                        checked={formik.values.firstCohort === "Cohort 0"}
                        onChange={() =>
                          formik.setFieldValue("firstCohort", "Cohort 0")
                        }
                      />
                      <span></span>Cohort 0
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="firstCohort"
                        value="Cohort 1"
                        checked={formik.values.firstCohort === "Cohort 1"}
                        onChange={() =>
                          formik.setFieldValue("firstCohort", "Cohort 1")
                        }
                      />
                      <span></span>Cohort 1
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="firstCohort"
                        value="Cohort 2"
                        checked={formik.values.firstCohort === "Cohort 2"}
                        onChange={() =>
                          formik.setFieldValue("firstCohort", "Cohort 2")
                        }
                      />
                      <span></span>Cohort 2
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="firstCohort"
                        value="Cohort 3"
                        checked={formik.values.firstCohort === "Cohort 3"}
                        onChange={() =>
                          formik.setFieldValue("firstCohort", "Cohort 3")
                        }
                      />
                      <span></span>Cohort 3
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="firstCohort"
                        value="Cohort 4"
                        checked={formik.values.firstCohort === "Cohort 4"}
                        onChange={() =>
                          formik.setFieldValue("firstCohort", "Cohort 4")
                        }
                      />
                      <span></span>Cohort 4
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="firstCohort"
                        value="Cohort 5"
                        checked={formik.values.firstCohort === "Cohort 5"}
                        onChange={() =>
                          formik.setFieldValue("firstCohort", "Cohort 5")
                        }
                      />
                      <span></span>Cohort 5
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="firstCohort"
                        value="Cohort 6"
                        checked={formik.values.firstCohort === "Cohort 6"}
                        onChange={() =>
                          formik.setFieldValue("firstCohort", "Cohort 6")
                        }
                      />
                      <span></span>Cohort 6
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="firstCohort"
                        value="Cohort 7"
                        checked={formik.values.firstCohort === "Cohort 7"}
                        onChange={() =>
                          formik.setFieldValue("firstCohort", "Cohort 7")
                        }
                      />
                      <span></span>Cohort 7
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="firstCohort"
                        value="Cohort 8"
                        checked={formik.values.firstCohort === "Cohort 8"}
                        onChange={() =>
                          formik.setFieldValue("firstCohort", "Cohort 8")
                        }
                      />
                      <span></span>Cohort 8
                    </label>
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="firstCohort"
                        value="Cohort 9"
                        checked={formik.values.firstCohort === "Cohort 9"}
                        onChange={() =>
                          formik.setFieldValue("firstCohort", "Cohort 9")
                        }
                      />
                      <span></span>Cohort 9
                    </label>
                  </div>
                </div>
                <input
                  hidden
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "firstCohort"
                  )}`}
                  {...formik.getFieldProps("firstCohort")}
                />
                {formik.touched.firstCohort && formik.errors.firstCohort ? (
                  <div className="invalid-feedback">
                    {formik.errors.firstCohort}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-12 col-lg-12 col-form-label">
                Will you need work authorization that authorizes you to work for
                any employer where you are presently based without restrictions?
              </label>
              <label className="col-xl-3 col-lg-3 col-form-label"> </label>
              <div className="col-lg-9 col-xl-6">
                <div className="col-form-label">
                  <div className="radio-inline">
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="workAuthorization"
                        value="YES"
                        checked={formik.values.workAuthorization === "YES"}
                        onChange={() =>
                          formik.setFieldValue("workAuthorization", "YES")
                        }
                      />
                      <span></span>YES
                    </label>

                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="workAuthorization"
                        value="NO"
                        checked={formik.values.workAuthorization === "NO"}
                        onChange={() =>
                          formik.setFieldValue("workAuthorization", "NO")
                        }
                      />
                      <span></span>NO
                    </label>
                  </div>
                </div>
                <input
                  hidden
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "workAuthorization"
                  )}`}
                  {...formik.getFieldProps("workAuthorization")}
                />
                {formik.touched.workAuthorization &&
                formik.errors.workAuthorization ? (
                  <div className="invalid-feedback">
                    {formik.errors.workAuthorization}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-12 col-lg-12 col-form-label">
                Are you interested in becoming a Group Leader or Assistant Group
                Leader? Best performing group leaders that can inspire and lead
                their team to success get rewarded at the end of the training
                program. Some group leaders can also use their experience as
                part of their resume and reference from Blackforce i.e. Lead
                Salesforce Consultant
              </label>
              <label className="col-xl-3 col-lg-3 col-form-label"> </label>
              <div className="col-lg-9 col-xl-6">
                <div className="col-form-label">
                  <div className="radio-inline">
                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="interestedInGroupLeader"
                        value="YES"
                        checked={
                          formik.values.interestedInGroupLeader === "YES"
                        }
                        onChange={() =>
                          formik.setFieldValue("interestedInGroupLeader", "YES")
                        }
                      />
                      <span></span>YES
                    </label>

                    <label className="radio radio-primary">
                      <input
                        disabled={toggleEdit}
                        type="radio"
                        name="interestedInGroupLeader"
                        value="NO"
                        checked={formik.values.interestedInGroupLeader === "NO"}
                        onChange={() =>
                          formik.setFieldValue("interestedInGroupLeader", "NO")
                        }
                      />
                      <span></span>NO
                    </label>
                  </div>
                </div>
                <input
                  hidden
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "interestedInGroupLeader"
                  )}`}
                  {...formik.getFieldProps("interestedInGroupLeader")}
                />
                {formik.touched.interestedInGroupLeader &&
                formik.errors.interestedInGroupLeader ? (
                  <div className="invalid-feedback">
                    {formik.errors.interestedInGroupLeader}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Attach Resume
              </label>
              <div className="col-lg-9 col-xl-6">
                {!profile ? (
                  <>
                    {!displayFile ? (
                      <div className="mb-lg-0 mb-2">
                        <span
                          className="btn btn-light-primary font-weight-bold btn-sm"
                          onClick={() => showUploadResumeModal()}
                        >
                          Click to upload
                        </span>
                        <span className="form-text text-muted">
                          Max file size is 5MB and max number of files is 1.
                        </span>
                      </div>
                    ) : (
                      <>
                        {" "}
                        <div className="form-group row">
                          <div className="col-lg-10 col-md-10 col-sm-12 ml-10 mt-10">
                            <div className="dropzone-items">
                              <div className="dropzone-item">
                                <div className="dropzone-file">
                                  <div
                                    className="dropzone-filename"
                                    title="some_image_file_name.jpg"
                                  >
                                    <span data-dz-name="">
                                      {displayFile.path}
                                    </span>
                                    <strong>
                                      (
                                      <span data-dz-size="">{`${displayFile.size}kb`}</span>
                                      )
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="form-group row">
                      <div className="col-lg-10 col-md-10 col-sm-12 ml-10 mt-10">
                        {/* <div className="mb-lg-0 mb-2"> */}
                        <a
                          href={profile?.resumeUrl}
                          rel="noreferrer"
                          target="_blank"
                          className="btn btn-light-primary font-weight-bold btn-sm"
                        >
                          Click to view Resume
                        </a>
                        {/* </div> */}
                        {/* <span data-dz-name="">{profile?.resumeUrl}</span> */}
                      </div>
                    </div>
                  </>
                )}
                <input
                  hidden
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "resumeUrl"
                  )}`}
                  {...formik.getFieldProps("resumeUrl")}
                />
                {formik.touched.resumeUrl && formik.errors.resumeUrl ? (
                  <div className="invalid-feedback">
                    {formik.errors.resumeUrl}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* end::Body */}
          {/* <div className="separator separator-solid my-7"></div> */}
        </div>

        {/* end::Form */}
      </form>
      <UploadCsvDialog
        show={showModal}
        onHide={handleCloseResumeModal}
        setResumeUrl={url => formik.setFieldValue("resumeUrl", url)}
        setDisplayFile={setDisplayFile}
      />
    </>
  );
}

export default ReviewForm;
